import * as React from 'react';
import withStyles from 'react-jss';
import { CssType, ThemeType } from '@theming/jssTypes';
import { formatDate, formatDateTime, parseMinuteSeconds } from '@utils/utils';
import { getConsignmentStatusPrettyName } from '@utils/consignmentStatus';
import {
  WarningFilled,
  CheckCircleFilled,
  CloseCircleFilled,
} from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import JsonArrayTableViewModal from 'src/components/pages/dashboard/JsonArrayTableViewModal';

const styles = (theme: ThemeType): CssType => ({
  derivedCol: {
    display: 'flex',
    alignItems: 'center',
  },
  statusCircle: {
    width: 12,
    height: 12,
    marginRight: 8,
    borderRadius: 8,
  },
  textCol: {
    color: '#262626',
    textTransform: 'capitalize',
  },
  divFlex: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  container: {
    margin: '0px 12px',
    padding: '1rem',
    backgroundColor: '#ffffff',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
    border: '1px solid #D9D9D9',
    borderRadius: '8px',
  },
  columnDiv: {
    margin: '8px 16px',
  },
  hint: {
    fontSize: '10px',
    color: '#595959',
    fontWeight: 400,
  },
  content: {
    fontSize: '12px',
    color: '#262626',
    fontWeight: 500,
  },
  viewDetails: {
    color: '#1890FF',
    cursor: 'pointer',
  },
});

const HeaderCard = (props: any) => {
  const { classes, columns, data, fullHeadersList, viewSource, objectType, ...otherProps } =
    props;
  const { t } = useTranslation();

  const [jsonArrayFieldListModalProps, setJsonArrayFieldListModalProps] = React.useState({
    data: [],
    isVisible: false,
    key: '',
  });

  const isValue = (data) => {
    if (Number.isInteger(data)) {
      return data;
    }
    return t('Not Available');
  };

  const renderEWBStatus = (x) => {
    const { classes } = props;
    return x === 'success' ? (
      <span>
        <CheckCircleFilled
          style={{ color: '#008000' }}
          className={classes.leftSpace}
        />
        {t('Success')}
      </span>
    ) : (
      <span>
        <CloseCircleFilled
          style={{ color: '#EC544A' }}
          className={classes.leftSpace}
        />
        {t('Failed')}
      </span>
    );
  };

  const isValidVal = (val) => {
    const { t } = props;
    if (Number.isInteger(val) || isFloat(val)) {
      return val;
    }
    return t('Not Available');
  };

  const isFloat = (n) => {
    return Number(n) === n && n % 1 !== 0;
  };

  const getTotalTripTime = (estimated_time_seconds, service_time_mins) => {
    let totalTripTime = null;
    const serviceTimeMins = isValidVal(service_time_mins);
    if (serviceTimeMins !== 'Not Available') {
      totalTripTime = estimated_time_seconds
        ? parseMinuteSeconds(estimated_time_seconds + service_time_mins * 60)
        : null;
    } else {
      totalTripTime = estimated_time_seconds
        ? parseMinuteSeconds(estimated_time_seconds)
        : null;
    }

    return totalTripTime;
  };

  const getDataToRender = (data, column) => {
    switch (column.id) {
      case 'status':
        return (
          <div className={classes.derivedCol}>
            <div
              className={classes.statusCircle}
              style={{
                backgroundColor: '#4cba38', // colorStatusMapping[status.toLowerCase()]
              }}
            ></div>
            <div
              className={classes.textCol}
              style={{ textTransform: 'capitalize' }}
            >
              {t(getConsignmentStatusPrettyName(data[column.id]))}
            </div>
          </div>
        );
      case 'created_at':
      case 'last_main_event_time':
      case 'scheduled_at':
        return data[column.id] ? formatDateTime(data[column.id]) : t('Not Available');
      case 'updated_at':
      case 'start_time':
      case 'end_time':
      case 'simulated_start_time':
      case 'simulated_end_time':
        return formatDateTime(data[column.id]);
      case 'delivered_task_count':
      case 'attempted_task_count':
      case 'pickedup_task_count':
      case 'notpickedup_task_count':
      case 'incomplete_delivery_task_count':
      case 'incomplete_pickup_task_count':
      case 'total_task_count':
        return isValue(data[column.id]);
      case 'is_partial':
      case 'is_partial_delivery':
        if (!data.parent_reference_number) {
          return data[column.id] ? (
            <div>
              {t('Yes')}
              <Tooltip title={t('Partial')}>
                <WarningFilled
                  style={{ fontSize: 18, color: '#EC544A', marginLeft: 8 }}
                />
              </Tooltip>
            </div>
          ) : (
            t('No')
          );
        }
        return null;
      case 'is_partial_pickedup':
        if (!data.parent_reference_number) {
          return data[column.id] === 'Yes' ? (
            <div>
              {t('Yes')}
              <Tooltip title={t('Partial')}>
                <WarningFilled
                  style={{ fontSize: 18, color: '#EC544A', marginLeft: 8 }}
                />
              </Tooltip>
            </div>
          ) : (
            t('No')
          );
        }
        return null;
      case 'estimated_distance_km':
        return `${data[column.id]} km`;
      case 'ewb_status':
        return renderEWBStatus(data[column.id]);
      case 'consignment_volume':
        return data['volume'] ? data['volume'] : t('Not Available');
      case 'attempt_count':
      case 'pickup_attempt_count':
        return data[column.id] ? data[column.id] : 0;
      case 'helper_name':
        return otherProps?.showHelperName ? otherProps.showHelperName() : null;
      case 'worker_name':
        return otherProps?.showWorkerName ? otherProps?.showWorkerName() : null;
      case 'scheduled_worker_name':
        return otherProps?.showScheduledWorkerName ? otherProps?.showScheduledWorkerName() : null;
      case 'vehicle_registration_number':
        return otherProps?.showVehicleRegNo
          ? otherProps.showVehicleRegNo()
          : null;
      case 'scheduled_vehicle_registration_number':
        return otherProps?.showScheduledVehicle
          ? otherProps.showScheduledVehicle()
          : null;
      case '{estimated_time_seconds,service_time_mins}':
        return getTotalTripTime(
          data['estimated_time_seconds'],
          data['service_time_mins'],
        );
      case 'total_amount':
        return isValidVal(data['cod_value']);
      case 'total_volume':
        return isValidVal(data['volume']);
      case 'cod_value':
      case 'service_time_mins':
      case 'vehicle_service_time_mins':
        return isValidVal(data[column.id]);
      case 'planning_hub':
        return data['hub_name'];
      case 'trip_charges':
        return otherProps?.showTripCharges
          ? otherProps.showTripCharges()
          : null;
      case 'is_manually_edited':
        return data[column.id] ? t('Yes') : t('No');
      default:
        if(column.type === 'boolean'){
          return data[column.id] ? t('Yes') : t('No');
        }
        if (column.type === 'jsonArray' && Array.isArray(data[column.id]) && data[column.id].length) {
          return (
            <div
              className={classes.viewDetails}
              onClick={() => setJsonArrayFieldListModalProps({
                data: data[column.id],
                isVisible: true,
                key: column.id,
              })}
            >View Details</div>
          );
        }
        return data[column.id] ? t( data[column.id]) : t('Not Available');
    }
  };

  const renderTripKeyValuePairs = (columns, fullHeadersList) => {
    const PRIMARY_TRIP = 'primary';
    const SECONDARY_TRIP = 'secondary';
    const DIRECT_TRIP = 'direct';
    const detailList = columns?.map((col, index) => {
      const column = fullHeadersList.find((header) => header.id === col.id);
      const detailData = [];
      if (!col.show_on_main_dashboard) return null;

      switch (column.key) {
        case 'secondary_trips_count':
          if (data['trip_type'] !== DIRECT_TRIP) {
            if (data['trip_type'] === PRIMARY_TRIP) {
              detailData.push(
                <div key={index} className={classes.columnDiv}>
                  <div className={classes.hint}>{t(column.pretty_name)}</div>
                  <div className={classes.content}>
                    {getDataToRender(data, column)}
                  </div>
                </div>,
              );
            }
          }
          break;
        case 'primary_organisation_reference_number':
          if (data['trip_type'] !== DIRECT_TRIP) {
            if (data['trip_type'] !== PRIMARY_TRIP) {
              detailData.push(
                <div key={index} className={classes.columnDiv}>
                  <div className={classes.hint}>{t(column.pretty_name)}</div>
                  <div className={classes.content}>
                    {getDataToRender(data, column)}
                  </div>
                </div>,
              );
            }
          }
          break;
        case 'primary_hub_name':
          if (data['trip_type'] !== DIRECT_TRIP) {
            if (data['trip_type'] !== PRIMARY_TRIP) {
              detailData.push(
                <div key={index} className={classes.columnDiv}>
                  <div className={classes.hint}>{t(column.pretty_name)}</div>
                  <div className={classes.content}>
                    {getDataToRender(data, column)}
                  </div>
                </div>,
              );
            }
          }
          break;
        case 'planning_hub':
          detailData.push(
            <div key={index} className={classes.columnDiv}>
              <div className={classes.hint}>
                {data['trip_type'] !== SECONDARY_TRIP ? t('Planning Hub') : t('Hub')}
              </div>
              <div className={classes.content}>
                {getDataToRender(data, column)}
              </div>
            </div>,
          );
          break;
        default:
          detailData.push(
            <div key={index} className={classes.columnDiv}>
              <div className={classes.hint}>{t(column.pretty_name)}</div>
              <div className={classes.content}>
                {getDataToRender(data, column)}
              </div>
            </div>,
          );
      }
      return detailData;
    });
    return detailList;
  };

  const ignoreColumn = (data, column) => {
    switch (column.id) {
      case 'is_partial':
      case 'is_partial_pickedup':
      case 'is_partial_delivery':
        return data.parent_reference_number ? true : false;
      default:
        return false;
    }
  };

  const renderKeyValuePairs = (columns) => {
    const detailData: any = [];
    columns?.forEach((column, index) => {
      if (!ignoreColumn(data, column)) {
        detailData.push(
          <div key={index} className={classes.columnDiv}>
            <div className={classes.hint}>{t(column.pretty_name)}</div>
            <div className={classes.content}>
              {getDataToRender(data, column)}
            </div>
          </div>,
        );
      }
    });
    return detailData;
  };

  const renderColumns = (viewSource) => {
    switch (viewSource) {
      case 'trip details':
        return renderTripKeyValuePairs(columns, fullHeadersList);
        break;
      default:
        return renderKeyValuePairs(columns);
    }
  };

  const renderJsonArrayTableViewModal = () => {
    if (!jsonArrayFieldListModalProps.isVisible) return;

    return (
      <JsonArrayTableViewModal
        isVisible={jsonArrayFieldListModalProps.isVisible}
        data={jsonArrayFieldListModalProps.data}
        objectType={objectType}
        handleClose={() => {
          setJsonArrayFieldListModalProps({
            data: [],
            isVisible: false,
            key: '',
          });
        }}
        columnKey={jsonArrayFieldListModalProps.key}
      />
    )
  }
  return (
    <div className={classes.container}>
      <div className={classes.divFlex}>{renderColumns(viewSource)}</div>
      {renderJsonArrayTableViewModal()}
    </div>
  );
};
const StyledHeaderCard = withStyles(styles, { injectTheme: true })(
  HeaderCard as React.ComponentType<any>,
);
export default StyledHeaderCard;
