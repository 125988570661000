import * as React from 'react';
import * as lodash from 'lodash';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Image, Tooltip, Modal, Carousel, Typography } from 'antd';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { formatDate, formatDateTime } from '@utils/utils';
import { getDetailsRoute, getRetailTripDetailsRoute } from '@routing/utils';
import {
  getTMSIstDetailsRoute,
  getTMSBagDetailsRoute,
} from '@components/pages/TMSDashboard/routes';
import { getConsignmentStatusPrettyName } from '@utils/consignmentStatus';
import {
  getRetailConsignmentStatus,
  tripStatusPrettyNames,
  getRetailInTripConsignmentStatus,
} from '@utils/retailConsignment';
import * as moment from 'moment';
import withStyles from 'react-jss';
import { CssType, ThemeType } from '@theming/jssTypes';
import { connect } from 'react-redux';

const PreviewGroup = Image.PreviewGroup;

import { InfoCircleOutlined, WarningFilled } from '@ant-design/icons';
import EwayBillListModal from '../EwayBillListModal';
import PriceDataListModal from '../PriceDataListModal';
import InvoiceListModal from '../InvoiceListModal';
import EwayBillPartBListModal from '../EwayBillPartBListModal';
import useGenericState from '@hooks/useGenericState';
import TableModal from '@components/common/TableModal';
import GalleryModal from '@components/common/galleryModal';
import PodImageModal from '@components/pages/FoodDashboard/PodImageModal';
import TextModal from '@components/common/TextModal';
import { getPickupRequestDetailsRoute } from '@routing/utils';
import TextWithEllipsisAndTooltip from '@components/common/TextWithEllipsisAndTooltip';
import { givenPaymentDetailsColumns } from './columns';
import DocumentLinkModal from '@components/common/DocumentLinkModal';
import DocumentDetailsModal from '@components/common/DocumentDetailsModal';
import { useTranslation } from 'react-i18next';
import JsonArrayTableViewModal from 'src/components/pages/dashboard/JsonArrayTableViewModal';

interface GalleryModalData {
  images: string[];
  videos: string[];
  pdfs: string[];
}

const { useState } = React;
const { Paragraph } = Typography;

const styles = (theme: ThemeType): CssType => ({
  container: {},
  title: {
    height: '48px',
    padding: '0 40px',
    backgroundColor: '#fcfcfc',
    fontSize: '14px',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    color: '#424242',
  },
  rowContainer: {
    padding: '0px 10px 0px 40px',
    marginBottom: '20px',
  },
  hintHeader: {
    fontSize: '10px',
    color: '#595959',
    fontWeight: 400,
    margin: '6px 0px 6px 0',
  },
  content: {
    fontSize: '12px',
    color: '#262626',
    fontWeight: 500,
    wordBreak: 'break-word',
    textTransform: 'capitalize',
  },
  column: {
    margin: '10px 0px',
  },
  btn: {
    color: 'blue',
    cursor: 'default',
  },
  modalContainer: {
    padding: '1rem 0',
    paddingBottom: '3rem',
  },
  carouselContainer: {
    padding: '1.5rem',
    maxWidth: '100%',
    '& span > svg': {
      width: '120%',
      height: '120%',
    },
  },
  viewDetails: {
    color: '#1890FF',
    cursor: 'pointer',
  },
});

const DetailsCard = (props: any) => {
  const {
    title,
    data,
    columns,
    classes,
    allowPartialDelivery,
    getCustomDetailsCard,
    objectType,
  } = props;
  const [visible, setVisible] = React.useState(false);
  const [galleryData, setGalleryData] = React.useState(null);
  const [isEwayBillListModalVisible, setEwayBillListModalVisible] =
    useState(false);
  const [isPriceDataListModalVisible, setIsPriceDataListModalVisible] =
    useState(false);
  const [priceDataList, setPriceDataList] = useState([]);
  const [invoiceList, setInvoiceList] = useState([]);
  const [isInvoiceListModalVisible, setIsInvoiceListModalVisible] = useState(false);
  const [isEwayBillPartBListModalVisible, setEwayBillPartBListModalVisible] =
    useState(false);
  const [ewayBillList, setEwayBillList] = useState([]);
  const [isGalleryModalVisible, setIsGalleryModalVisible] = React.useState(false);
  const [galleryModalData, setGalleryModalData] =
    React.useState<GalleryModalData>({ images: [], videos: [], pdfs: [] });
  const [isTextModalVisible, setIsTextModalVisible] = useState(false);
  const [feedbackText, setFeedbackText] = React.useState(null);
  const [documentDetails, setDocumentDetails] = React.useState(null);
  const [documentLinkModalVisible, setDocumentLinkModalVisible] = React.useState(false);
  const [jsonArrayFieldListModalProps, setJsonArrayFieldListModalProps] = React.useState({
    data: [],
    isVisible: false,
    key: '',
  });

  const handleCustomerFeedbackClick = (data) => {
    setIsTextModalVisible(!isTextModalVisible);
    setFeedbackText(data);
  };

  const initialState = {
    isTableModalVisible: false,
    isPodModalVisible: false,
    dataSource: [],
    podImagesList: [],
    columnsData: [],
    header: '',
  };

  const [state, setState] = useGenericState(initialState);

  const {
    isTableModalVisible,
    isPodModalVisible,
    dataSource,
    podImagesList,
    columnsData,
    header,
  } = state;

  const renderGalleryModal = (imageArray: any[], videoArray: any[], pdfArray: any[]) => {
    if (imageArray.length === 0 && videoArray.length === 0 && pdfArray.length === 0) return t('not available');
    return (
        <a onClick={() => {
            setGalleryModalData({
                images: imageArray,
                videos: videoArray,
                pdfs: pdfArray,
            });
            setIsGalleryModalVisible(true);
        }}
        >
            {t('View')}
        </a>
    );
};

  const renderInvoiceList = (ewayBill, ewayBillList, invoiceList = [], invoiceNumber, invoiceValue) => {
    try {
      ewayBill = JSON.parse(ewayBill.trim());
    } catch (err) {
      ewayBill = null;
    }
    if (ewayBill) {
      const isEwayBillFoundInList = ewayBillList?.length ? ewayBillList.find(ewayBillObj => ewayBillObj.invoiceNumber === ewayBill.invoiceNumber) : false;
      if (!isEwayBillFoundInList) {
        ewayBillList = ewayBillList || [];
        ewayBillList.push(ewayBill);
      }
    }
    if(invoiceNumber && invoiceValue){
      const isInvoiceFoundInList = invoiceList?.length ? invoiceList.find(invoiceObj => invoiceObj.invoiceNumber === invoiceNumber) : false;
      if (!isInvoiceFoundInList) {
        invoiceList.push({invoiceNumber: invoiceNumber, invoiceValue: invoiceValue});
      }
    }
    ewayBillList = ewayBillList.filter(ewayBillObj => ewayBillObj.invoiceNumber);
    invoiceList = invoiceList.filter(invoiceObj => invoiceObj.invoiceNumber);
    let dataToRender = [
      ...invoiceList,
      ...ewayBillList,
    ];
    dataToRender = lodash.uniqBy(dataToRender, 'invoiceNumber');

    setIsInvoiceListModalVisible(true);
    setInvoiceList(dataToRender);
  };

  const renderPriceDataList = (priceData) => {
    setIsPriceDataListModalVisible(true);
    setPriceDataList(priceData);
  };

  const renderPriceDetails = (data) => {
    if (Array.isArray(data) && data.length) {
      const finalPriceObject = data.find((price) => (price.id === 'finalPrice'));
      if (!finalPriceObject) {
        return t('not available');
      }
      const filteredPriceData = data.filter((price) => price.id !== 'finalPrice');
      return (
      <span>
        {(finalPriceObject['value'] || 0)?.toString()}
        <Tooltip title="">
          <a>
            <InfoCircleOutlined
              style={{ marginLeft: 5 }}
              onClick={() => renderPriceDataList(filteredPriceData)}
            ></InfoCircleOutlined>
          </a>
        </Tooltip>
      </span>);

    }
    return t('not available');
  };

  const getDataToRender = (column, data) => {
    if (column.type === 'boolean') {
      return renderBooleanData(column, data);
    }
    if (column.type === 'Array') {
      return renderArrayData(column, data);
    }
    if (column.type === 'date') {
      switch (column.id) {
        case 'hub_arrival_time':
        case 'delivery_completion_time':
        case 'delivery_time_slot_start':
        case 'delivery_time_slot_end':
        case 'pickup_time_slot_start':
        case 'pickup_time_slot_end':
        case 'softdata_upload_time':
        case 'load_time_ondemand_timestamp':
        case 'order_time_ondemand':
        case 'makeline_time_ondemand':
          return formatDateTime(parseInt(data[column.id], 10));
        case 'expected_delivery_date':
        case 'promised_delivery_date':
          return formatDate(data[column.id], t('not available'));
        default:
          return formatDate(data[column.id], t('not available'));
      }
    }
    if (column.type === 'url') {
      return renderUrlData(data[column.id]);
    }
    if (column.type === 'jsonArray') {
      return (
       (Array.isArray(data[column.id]) && data[column.id].length) ? (
          <div
            className={classes.viewDetails}
            onClick={() => setJsonArrayFieldListModalProps({
              data: data[column.id],
              isVisible: true,
              key: column.id,
            })}
          >View Details</div>
        ) : '-'
      );
    }
    if (column.type === 'datetime') {
      return formatDateTime(data[column.id]);
    }
    switch (column.id) {
      case 'movement_type':
      case 'consignment_type':
        return data[column.id] !== null && data[column.id] !== undefined
          ? data[column.id]
          : 'Forward';
      case 'split_original_reference_number':
      case 'forward_reference_number':
      case 'rto_reference_number':
        return renderReferenceNos(data[column.id]);
      case 'bag_reference_number':
        return renderBagNo(data[column.id]);
      case 'trip_organisation_reference_number':
      case 'hubtohub_trip_organisation_reference_number':
      case 'endpoint_trip_organisation_reference_number':
      case 'pickup_trip_number':
      case 'delivery_trip_number':
        return renderTripNumber(data[column.id]);
      case 'middle_mile_trip_number':
        return renderTripNumber(data[column.id], true);
      case 'latest_pickup_task_reference_number':
        return renderPickupTaskReferenceNumber(data[column.id]);
      case 'status':
        return (
          <>
            {t(getConsignmentStatusPrettyName(data[column.id]))}
            { data.is_partial_delivery ? (
              <Tooltip title={t('Partially Delivered')}>
                <WarningFilled
                  style={{ fontSize: 18, color: '#EC544A', marginLeft: 8 }}
                />
              </Tooltip>
            ) : data.is_partial_pickup === 'Yes' ?
            (
              <Tooltip title={t('Partially Pickedup')}>
                <WarningFilled
                  style={{ fontSize: 18, color: '#EC544A', marginLeft: 8 }}
                />
              </Tooltip>
            ) : <></>}
          </>
        );
      case 'invoice_number': 
        const invoiceList = lodash.get(data, 'extra_details.invoice_list', []);
        const invoiceNumber = lodash.get(data, 'extra_details.invoice_number', '');
        const invoiceValue = lodash.get(data, 'extra_details.declared_value', '');
        return (
          <Tooltip title="">
            <a>
              <div
                onClick={() => renderInvoiceList(data['eway_bill'], data['eway_bill_list'], invoiceList, invoiceNumber, invoiceValue)}
              >{t('View')}</div>
            </a>
          </Tooltip>
        );
      case 'cod_amount':
        return data[column.id] || 0;
      case 'cod_amount_collected':
        return renderAmountCollected(data[column.id], data['cod_amount']);
      case 'signature_image':
      case 'pop_signature_image':
      case 'pod_image':
      case 'poa_image':
      case 'delivery_kyc_image':
      case 'reference_image_url':
      case 'pickup_premise_image':
      case 'card_transaction_image':
      case 'pickup_signature_image':
        return data[column.id] ? renderGalleryModal([data[column.id]], [], []) : t('not available');

      case 'delivery_time_slot_start':
      case 'delivery_time_slot_end':
      case 'pickup_time_slot_start':
      case 'pickup_time_slot_end':
        return data[column.id]
          ? moment(parseInt(data[column.id], 10)).format('HH:mm')
          : t('not available');
      case 'execution_status':
        return;
      case 'worker_comment':
        return renderTooltipParagraph(data[column.id]);
      case 'ewb_partb_generation_time':
        return renderEWBPartBGenerationTime(data[column.id]);
      case 'destination_lat':
        return Array.isArray(data.delivery_location) &&
          data.delivery_location?.length === 2
          ? data.delivery_location[0]
          : data.destination_latitude
          ? data.destination_latitude
          : t('not available');
      case 'destination_lon':
        return Array.isArray(data.delivery_location) &&
          data.delivery_location?.length === 2
          ? data.delivery_location[1]
          : data.destination_longitude
          ? data.destination_longitude
          : t('not available');
      case 'pickup_lat':
        return data.pickup_completed_event_details?.lat
          ? data.pickup_completed_event_details?.lat
          : t('not available');
      case 'pickup_lng':
        return data.pickup_completed_event_details?.lng
          ? data.pickup_completed_event_details?.lng
          : t('not available');
      case 'origin_lat':
        return Array.isArray(data.origin_location) &&
          data.origin_location?.length === 2
          ? data.origin_location[0]
          : t('not available');
      case 'origin_lon':
        return Array.isArray(data.origin_location) &&
          data.origin_location?.length === 2
          ? data.origin_location[1]
          : t('not available');
      case 'latest_delivery_geocode_difference':
        return (
          data?.latest_delivery_event?.geocode_location_difference ||
          t('not available')
        );
      case 'latest_pickup_geocode_difference':
        return (
          data?.latest_pickup_event?.geocode_location_difference ||
          t('not available')
        );
      case 'customer_feedback':
        return data.customer_feedback ? (
          <span
            onClick={() => handleCustomerFeedbackClick(data.customer_feedback)}
          >
            {t('View')}
          </span>
        ) : (
          t('not available')
        );
      case 'document_link':
        return renderDocumentList(data);
      default:
        return data[column?.id] ? data[column?.id] : t('not available');
    }
  };

  const renderBooleanData = (column, data) => {
    switch (column.id) {
      case 'is_cnv_done':
        return data[column.id] ? 'Verified' : 'Not Verified';
      default:
        return data[column?.id] ? 'Yes' : 'No';
    }
  };

  const getImageVideosFromPieces = (data) => {
    const pieceImagesToShow = [];
    const pieceVideosToShow = [];

    data['pieces_detail']?.map((piece) => {
      // Piece level images/videos
      const imageUrlListPiece = piece?._internal?.image_url_list?.map(
        (image) => {
          if (image) {
            pieceImagesToShow.push(image);
          }
        },
      );
      const videoUrlListPiece = piece?._internal?.video_url_list?.map(
        (video) => {
          if (video) {
            pieceVideosToShow.push(video);
          }
        },
      );
    });

    return { pieceImagesToShow, pieceVideosToShow };
  };

  const checkAndRenderGallery = (
    column,
    data,
    statusList,
    appendPieceImages = false,
    ignoreStatusCheck = false,
  ) => {
    if (!ignoreStatusCheck) {
      if (!statusList?.includes(data['status'])) {
        return t('not available');
      }
    }
    if (data[column.id[0]]?.length > 0 || data[column.id[1]]?.length > 0) {
      let cnImages = [];
      data[column.id[0]]?.map((image) => {
        if (image) {
          cnImages.push(image);
        }
      });
      let cnVideos = [];
      data[column.id[1]]?.map((video) => {
        if (video) {
          cnVideos.push(video);
        }
      });

      if (appendPieceImages) {
        const { pieceImagesToShow, pieceVideosToShow } =
          getImageVideosFromPieces(data);
        cnImages = [...cnImages, ...pieceImagesToShow];
        cnVideos = [...cnVideos, ...pieceVideosToShow];
      }

      return renderGalleryModal(cnImages, cnVideos, []);
    }
    if (data[column.id[2]]) {
      return renderGalleryModal([data[column.id[2]]], [], []);
    }
    if (data[column.id[3]]) {
      //case of latest_pickup_proof_of_pickup_image -> RiderApp Field
      return renderGalleryModal([data[column.id[3]]], [], []);
    }
    return t('not available');
  };

  const renderUrlData = (column) => {
    if (!column) return t('not available');
    return (
      <a href={column} target="_blank">
        Preview
      </a>
    );
  };

  const renderArrayData = (column, data) => {
    switch (column.key) {
      case '{current_delivery_address_line_1, current_delivery_address_line_2}':
      case '{destination_address_line_1, destination_address_line_2}':
      case '{sender_address_line_1, sender_address_line_2}':
      case '{hub_address_line_1, hub_address_line_2}':
      case '{return_address_line_1, return_address_line_2}':
        return renderAddress(column, data);
      case '{manifest_number, manifest_time}':
        return `${data.manifest_number ? data.manifest_number : t('not available')}
                      ${data.manifest_time ? data.manifest_time : ''}`;
      case '{start_time, end_time}':
        return renderETA(data[column.id[0]], data[column.id[1]]);
      case 'eway_bill':
        return renderEwayBillNo(data[column.id], data['eway_bill_list']);
      case 'eway_bill_expiry_date':
        return renderEwayBillValidity(data[column.id]);
      case 'eway_bill_part_b':
        return renderEwayBillPartB(data[column.id], data['eway_bill_list']);
      case 'customer_pod':
        return renderGalleryModal(data[column.id] || [], [], []);
      case '{height, width, length}':
        return column.id.length === 3 &&
          data[column.id[0]] &&
          data[column.id[1]] &&
          data[column.id[2]]
          ? `${data[column.id[0]]}*${data[column.id[1]]}*${data[column.id[2]]}`
          : t('not available');
      case 'pickup_attempt_proof':
        return checkAndRenderGallery(column, data, ['not_picked_up']);
      case '{given_height, given_width, given_length}':
        return column.id.length === 3 &&
          data[column.id[0]] &&
          data[column.id[1]] &&
          data[column.id[2]]
          ? `${data[column.id[0]]}*${data[column.id[1]]}*${data[column.id[2]]}`
          : t('not available');
      case 'attempt_proof':
        return checkAndRenderGallery(column, data, ['attempted'], true, false);
      case 'pop_image':
        if (data['status'] === 'not_picked_up') {
          //since images displayed under pickup_attempt_proof
          return t('not available');
        }
        return checkAndRenderGallery(column, data, [], false, true);
      case 'poc_image':
        return checkAndRenderGallery(
          column,
          data,
          ['delivered', 'rto_delivered', 'rvp_delivered', 'undelivered', 'attempted'],
          true,
        );
      case 'given_payment_details':
        return renderTableModal(
          data[column.id],
          givenPaymentDetailsColumns,
          'Amount Paid Details',
        );
      case 'pod_image_url':
        return renderPodImage(data[column.id]);
      case 'constraint_tags':
        return renderConstraintTags(data[column.id]);
      case 'vendor_image_url_list':
        return renderVendorPODList(data[column.id]);
      case 'quality_check_image_list':
        return renderGallery(data[column.id]);
      case 'price_details':
        return renderPriceDetails(data[column.id]);
      case 'courier_price_details':
        return renderPriceDetails(data.courier_price_details_list);
      default:
        return data[column.id] ? data[column.id] : t('not available');
    }
  };

  const renderDocumentList = (data) => {
    const docDetails = data?.document_details;
    return (
      docDetails ?
      <a
        onClick={() =>
          {
            setDocumentLinkModalVisible(true);
            setDocumentDetails(docDetails);
          }
        }
        style={{ color: '#1890FF', cursor:'pointer' }}>{t('View')}
      </a>
      : t('not available')
    );
  };

  const renderVendorPODList = (podList) => {
    const imageList = [];
    const videoList = [];
    const pdfList = [];
    let podType;
    podList?.map((pod: { type: String; url: String }) => {
      podType = pod.type ? pod.type.toLowerCase() : null;
      switch (podType) {
        case 'jpg':
        case 'png':
        case 'jpeg':
          return imageList.push(pod);
        case 'mp4':
          return videoList.push(pod);
        case 'pdf':
          return pdfList.push(pod);
      }
    });
    return imageList.length > 0 || videoList.length > 0 || pdfList.length > 0
      ? renderGalleryModal(imageList, videoList, pdfList)
      : t('not available');
  };

  const renderGallery = (data) => {
    if (
      (Array.isArray(data) && !data.length) ||
      (!Array.isArray(data) && !data)
    ) {
      return t('not available');
    }
    return (
      <>
        <div
          onClick={() => {
            toggleGalleryWithData(data);
          }}
          className={classes.btn}
        >
          {t('View')}
        </div>
      </>
    );
  };

  const toggleGalleryWithData = (data = null) => {
    setVisible(!visible);
    setGalleryData(data);
  };

  const renderETA = (startTime, endTime) => {
    return startTime && endTime
      ? `${moment(startTime).format('Do MMMM')},
          ${moment(startTime).format('h A')} -
          ${moment(endTime).format('h A')}`
      : t('not available');
  };

  const renderEwayBillList = (ewayBillList) => {
    setEwayBillListModalVisible(true);
    setEwayBillList(ewayBillList);
  };
  const renderEwayBillPartBList = (ewayBillList) => {
    setEwayBillPartBListModalVisible(true);
    setEwayBillList(ewayBillList);
  };

  const renderEwayBillNo = (ewayBill, ewayBillList) => {
    if (ewayBill) {
      try {
        const wayBillObject = JSON.parse(ewayBill.trim());
        return (
          <span>
            {wayBillObject['ewbNumber']?.toString()}
            <Tooltip title="">
              <a>
                <InfoCircleOutlined
                  style={{ marginLeft: 5 }}
                  onClick={() => renderEwayBillList(ewayBillList)}
                ></InfoCircleOutlined>
              </a>
            </Tooltip>
          </span>
        );
      } catch (err) {
        return t('not available');
      }
    }
    return t('not available');
  };

  const renderEwayBillValidity = (ewayBill) => {
    if (ewayBill) {
      try {
        const wayBillObject = JSON.parse(ewayBill);
        return formatDate(wayBillObject['validTill'], t('not available'));
      } catch (err) {
        return t('not available');
      }
    }
    return t('not available');
  };

  const renderEWBPartBGenerationTime = (epochTime) => {
    if (epochTime) {
      try {
        return formatDate(epochTime, t('not available'));
      } catch (err) {
        return t('not available');
      }
    }
  };

  const renderEwayBillPartB = (ewayBill, ewayBillList) => {
    if (ewayBill) {
      try {
        const wayBillObject = JSON.parse(ewayBill.trim());
        return (
          <span>
            {wayBillObject['pickupCompletionPartBStatus']?.toString()}
            <Tooltip title="">
              <a>
                <InfoCircleOutlined
                  style={{ marginLeft: 5 }}
                  onClick={() => renderEwayBillPartBList(ewayBillList)}
                ></InfoCircleOutlined>
              </a>
            </Tooltip>
          </span>
        );
      } catch (err) {
        return t('not available');
      }
    }
    return t('not available');
  };

  const renderReferenceNos = (refNo) => {
    return refNo ? (
      <Link target={'_blank'} to={getDetailsRoute(refNo)}>
        {refNo}
      </Link>
    ) : (
      t('not available')
    );
  };

  const renderBagNo = (refNo) => {
    return refNo ? (
      <Link to={getTMSBagDetailsRoute(refNo)}>{refNo}</Link>
    ) : (
      t('not available')
    );
  };

  const renderTripNumber = (tripNo, isMiddleMileTripNo?: boolean) => {
    return tripNo ? (
      <Link
        to={
          isMiddleMileTripNo
            ? getTMSIstDetailsRoute(tripNo)
            : getRetailTripDetailsRoute(tripNo)
        }
      >
        {tripNo}
      </Link>
    ) : (
      t('not available')
    );
  };

  const renderPickupTaskReferenceNumber = (referenceNumber) => {
    return referenceNumber ? (
      <Link
        target={'_blank'}
        to={getPickupRequestDetailsRoute(referenceNumber)}
      >
        {referenceNumber}
      </Link>
    ) : (
      t('not available')
    );
  };

  const renderAmountCollected = (codAmountCollected, codAmount) => {
    let sum = 0;
    codAmountCollected?.map((ele) => (sum += ele.cod_amount));
    if (codAmount && !isNaN(codAmount) && sum > codAmount) {
      sum = codAmount;
    }
    return sum || t('not available');
  };

  const renderTooltipParagraph = (textToDisplay) => {
    return (
      <Tooltip
        overlayInnerStyle={{
          backgroundColor: '#fcfcfc',
          color: '#424242',
          border: '0.5px solid',
        }}
        autoAdjustOverflow={true}
        title={textToDisplay}
      >
        <Paragraph
          ellipsis={{
            rows: 3,
          }}
        >
          {textToDisplay ? textToDisplay : t('not available')}
        </Paragraph>
      </Tooltip>
    );
  };

  const renderTableModal = (data, columnsData, header?) => {
    return data ? (
      <span>
        <Tooltip title="">
          <a>
            <InfoCircleOutlined
              style={{ marginLeft: 5 }}
              onClick={() => {
                setState({
                  isTableModalVisible: true,
                  dataSource: data,
                  columnsData,
                  header,
                });
              }}
            ></InfoCircleOutlined>
          </a>
        </Tooltip>
      </span>
    ) : (
      t('not available')
    );
  };

  const renderPodImage = (data) => {
    return data?.length ? (
      <span
        onClick={() =>
          setState({
            isPodModalVisible: true,
            podImagesList: data,
          })
        }
      >
        <a>{t('View')}</a>
      </span>
    ) : (
      t('not available')
    );
  };

  const renderConstraintTags = (data) => {
    return data?.length ? (
      <span>
        <TextWithEllipsisAndTooltip width="500px">
          {data.map((item, index) => {
            if (index === data.length - 1) {
              return item;
            }
            return item + ', ';
          })}
        </TextWithEllipsisAndTooltip>
      </span>
    ) : (
      t('not available')
    );
  };

  const getModifiedColumns = (columns = []) => {
    const temp = [...columns];
    temp.forEach((item) => {
      item.column_id = item.id;
    });
    return temp;
  };

  const renderAddress = (column, data) => {
    return Array.isArray(column?.id) ? (
      <span>
        <TextWithEllipsisAndTooltip width="500px">
          <>
            {data[column.id[0]] ? data[column.id[0]] : ' - '}
            {data[column.id[1]] ? data[column.id[1]] : ''}
          </>
        </TextWithEllipsisAndTooltip>
      </span>
    ) : (
      t('not available')
    );
  };

  const { t } = useTranslation();
  return (
    <div className={classes.container}>
      <div className={classes.title}>{t(title)}</div>
      <div className={classes.rowContainer}>
        <Row gutter={40}>
          {columns?.map((column, index) => {
            if (getCustomDetailsCard) {
              const dataToRender = getCustomDetailsCard(column, data);
              return dataToRender?.hint ? (
                <Col span={column.size ? 6 * column.size : 6} key={index}>
                  <div className={classes.column}>
                    <div className={classes.hintHeader}>
                      {t(dataToRender.hint)}
                    </div>
                    <div className={classes.content}>
                      {dataToRender.content ?? '-'}
                    </div>
                  </div>
                </Col>
              ) : null;
            }
            return (
              column && (
                <Col span={column.size ? 6 * column.size : 6} key={index}>
                  <div className={classes.column}>
                    <div className={classes.hintHeader}>
                      {t(column.pretty_name)}
                    </div>
                    <div className={classes.content}>
                      {getDataToRender(column, data)}
                    </div>
                  </div>
                </Col>
              )
            );
          })}
        </Row>
        <div style={{ display: 'none' }}>
          <PreviewGroup
            preview={{
              visible,
              onVisibleChange: (vis) => toggleGalleryWithData(),
            }}
          >
            {Array.isArray(galleryData) ? (
              galleryData?.map((url) => <Image src={url} placeholder={true} />)
            ) : (
              <Image src={galleryData} placeholder={true} />
            )}
          </PreviewGroup>
        </div>
      </div>
      {isGalleryModalVisible && (
        <GalleryModal
          isVisible={isGalleryModalVisible}
          imageVideoGalleryData={galleryModalData}
          handleModalClose={() => { setIsGalleryModalVisible(false); }}
        />
      )}
      {isEwayBillListModalVisible && (
        <EwayBillListModal
          isVisible={isEwayBillListModalVisible}
          handleModalClose={(e) => setEwayBillListModalVisible(false)}
          ewayBillList={ewayBillList}
        />
      )}
      <InvoiceListModal
        isVisible={isInvoiceListModalVisible}
        handleModalClose={(e) => setIsInvoiceListModalVisible(false)}
        invoiceList={invoiceList}
      />
      {isEwayBillPartBListModalVisible && (
        <EwayBillPartBListModal
          isVisible={isEwayBillPartBListModalVisible}
          handleModalClose={(e) => setEwayBillPartBListModalVisible(false)}
          ewayBillList={ewayBillList}
        />
      )}
      {isTableModalVisible && (
        <TableModal
          header={header}
          isVisible={isTableModalVisible}
          handleModalClose={() =>
            setState({
              isTableModalVisible: false,
              dataSource: [],
              columnsData: [],
              header: '',
            })
          }
          dataSource={dataSource}
          columnsToShow={columnsData}
        />
      )}
      {isPodModalVisible && (
        <PodImageModal
          isVisible={isPodModalVisible}
          record={{ pod_image_url: podImagesList }}
          handleModalClose={() => setState({ isPodModalVisible: false })}
          title={'Delivery Image'}
        />
      )}
      {isTextModalVisible && (
        <TextModal
          isVisible={isTextModalVisible}
          text={feedbackText}
          title="Customer Feedback"
          onModalClose={() => setIsTextModalVisible(false)}
        />
      )}
      {
        documentLinkModalVisible && (
          <DocumentDetailsModal
            isVisible={documentLinkModalVisible}
            onModalClose={() => {
              setDocumentLinkModalVisible(false);
              setDocumentDetails(null);
            }}
            documentDetails = {documentDetails}
          />
        )
      }
      {isPriceDataListModalVisible && (
        <PriceDataListModal
          isVisible={isPriceDataListModalVisible}
          handleModalClose={(e) => setIsPriceDataListModalVisible(false)}
          priceDataList={priceDataList}
        />
      )}
      {jsonArrayFieldListModalProps.isVisible && (
        <JsonArrayTableViewModal
          isVisible={jsonArrayFieldListModalProps.isVisible}
          data={jsonArrayFieldListModalProps.data}
          objectType={objectType}
          handleClose={() => {
            setJsonArrayFieldListModalProps({
              data: [],
              isVisible: false,
              key: '',
            });
          }}
          columnKey={jsonArrayFieldListModalProps.key}
        />
      )}
    </div>
  );
};

const DetailsCardStyled = withStyles(styles, { injectTheme: true })(
  DetailsCard,
);

const mapStateToProps = ({ masterData }) => {
  return {
    allowPartialDelivery:
      masterData.ops_dashboard_config?.parts_to_show?.flm_trip_config
        ?.allow_partial_delivery,
  };
};

export default connect(mapStateToProps, null)(DetailsCardStyled);
